import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import JoinKuda from "../components/body/pages/joinKuda"

const JoinKudaPage = () => (
  <Layout>
    <SEO
      canonical={"https://kuda.com/joinKuda/"}
      title="Download Kuda | The Money App for Africans"
      description="Download Kuda, the money app for Africans licensed by the CBN. Zero maintenance fees, free transfers, automatic savings & investments. Join Kuda!"
    />
    <JoinKuda />
  </Layout>
)

export default JoinKudaPage
