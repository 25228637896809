import React, { useEffect, Fragment } from "react"
import BelowFoldCards from "../general/belowFoldCards"
import Card from "../../../../assets/img/kuda-app-miniIcon.inline.svg"
import Money from "../../../../assets/img/kuda-app-addminiIcon.inline.svg"
import Pay from "../../../../assets/img/kuda-app-openminiIcon.inline.svg"
import Illustration from "../../../../assets/img/kuda-open-account3.svg"
import { scrollToElement } from "../../../utility/utils"
import JoinKudaHeader from "./joinKudaHeader";

const entryContent = {
  title: "Download Kuda, open an account!",
  subtitle:
    "Scan the QR code below with your phone camera to download the Kuda app.",
  illustration: Illustration,
}

const kudaTopFeatures = [{
    icon: <Card />,
    text: "Download Kuda on Google Play or the App Store."
}, {
    icon: <Pay />,
    text: "Open the app and follow the prompts on your screen to open an account."
}, {
    icon: <Money />,
    text: "Add money to your account and start your new life of freedom from fees."
}]


const JoinKuda = () => {

    useEffect(() => {
        scrollToElement();
        window.addEventListener("scroll", function () {
            scrollToElement();
        });
    }, [])

    return (
        <Fragment>
            <JoinKudaHeader className="overflow-visible" 
            title={entryContent.title} 
            subtitle={entryContent.subtitle} 
            illustration={entryContent.illustration} 
            isBusiness={false} 
            
            />
            
            <BelowFoldCards topFeatures={kudaTopFeatures} />
        </Fragment>
    )
}

export default JoinKuda;
